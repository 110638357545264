/* Base Settings */
html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #fff; /* White background */
  color: #333; /* Dark gray text */
}

/* Chat Interface */
.chat-interface {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: transparent;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  padding: 20px;
  backdrop-filter: blur(20px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Light gray shadow */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #000; /* White background */
  justify-content: space-between;
}

/* Logo */
.logo {
  width: 180px;
  height: 40px;
  object-fit: contain;
}

/* New Chat Button */
.start-chat-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;
  background-color: #ff5722; /* Orange */
  color: #fff; /* White text */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  width: min-content;
}

.new-chat-btn:hover {
  background-color: #ff7043; /* Lighter orange on hover */
  transform: scale(1.1);
}

/* Logout Button */
.logout-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;
  background-color: #ff5722; /* Orange */
  color: #fff; /* White text */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  width: min-content;
}

.logout-btn:hover {
  background-color: #ff7043; /* Lighter orange on hover */
  transform: scale(1.1);
}

/* Chat Window */
.chat-window {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  backdrop-filter: blur(20px);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 80px;
  bottom: 80px;
  left: 0;
  right: 0;
  background-color: #fff; /* White background */
}

/* Message */
.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 16px;
  line-height: 1.4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5; /* Light gray background */
  text-align: justify;
  width: auto;
  color: #333; /* Dark gray text */
}

/* Icon */
.icon {
  margin-right: 10px;
  font-size: 20px;
  color: #333; /* Dark gray icon */
}

/* User Message */
.message.user-message {
  align-self: flex-end;
  background-color: #ff5722; /* Orange background */
  color: #fff; /* White text */
  width: auto;
}

/* AI Message */
.message.ai-message {
  align-self: flex-start;
  background-color: #333; /* Dark gray background */
  color: #fff; /* White text */
  width: auto;
}

/* Input Container */
.input-container {
  display: flex;
  align-items: first baseline;
  justify-content: flex-end;
  padding: 20px;
  background: transparent;
  backdrop-filter: blur(20px);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Light gray shadow */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #000; /* White background */
  height: min-content;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.input-container input {
  flex-grow: 1;
  padding: 10px 20px;
  margin: 10 20px;
  border-radius: 20px;
  outline: none;
  font-size: 16px;
  border: 2px solid rgba(0, 0, 0, 0.1); /* Light gray border */
  background: #f5f5f5; /* Light gray background */
  color: #333; /* Dark gray text */
  width: auto;
}

.input-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 10px;
  font-weight: bold;
  background-color: #ff5722; /* Orange background */
  color: #fff; /* White text */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  width: min-content;
  justify-content: flex-end;
}

.input-container button:hover {
  background-color: #ff7043; /* Lighter orange on hover */
}

.voice-input-btn.listening {
  background-color: black; /* No change needed */
}
