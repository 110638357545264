/* General Styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
}

/* Logo Styles */


/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

/* The hide and show effect */
@keyframes hide-show {
  0%, 50% { opacity: 0; } /* Hide for the first half of the animation */
  51%, 100% { opacity: 1; } /* Show for the second half of the animation */
}

/* Login Container */
.container {
  background-color: rgba(255, 255, 255, 0.05); /* Semi-transparent background */
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 90%;
  width: 90%;
  animation: fadeInUp 0.6s ease-in-out;
  backdrop-filter: blur(10px); /* Glassmorphism effect */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Login Form */
.form-container {
  width: 100%;
}

/* Input Fields */
input[type="email"],
input[type="password"] {
  width: calc(100% - 20px); /* Adjusting width to accommodate padding */
  padding: 10px;
  border: 1px solid #555; /* Darker border */
  border-radius: 8px;
  background-color: #444; /* Darker background */
  font-size: 14px;
  color: #fff; /* White text */
  margin: 5px 0; /* Adjusted margin */
}

/* Submit Button */
.toggle-button, .forgot-password {
  background-color: #ff5722; /* Deep orange */
  color: #000; /* Dark text */
  padding: 10px;
  border: 1px solid #555; /* Darker border */
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s ease;
  width: 100%; /* Increased button width */
  margin: 5px 0; /* Adjusted margin to match input fields */
}

.toggle-button:hover, .forgot-password:hover {
  background-color: #ff7043; /* Lighter deep orange */
}

/* Error Styles */
.message {
  color: #ff4d4d;
  font-size: 12px;
  margin-top: 5px;
}

/* Animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  body {
    height: 100%;
  }
  .container {
    max-width: 100%;
    padding: 20px;
  }
  input[type="email"],
  input[type="password"] {
    padding: 10px;
    font-size: 14px;
  }
  .toggle-button, .forgot-password {
    padding: 10px;
    font-size: 14px;
  }
}
.button-login {
  color: white;
  background-color: #ff5722; /* Deep orange */
  color: #000; /* Dark text */
  padding: 10px;
  border: 1px solid #555; /* Darker border */
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s ease;
  width: 100%; /* Increased button width */
  margin: 5px 0; /* Adjusted margin to match input fields */
}

.button-register {
  color: white;
  background-color: #ff5722; /* Deep orange */
  color: #000; /* Dark text */
  padding: 10px;
  border: 1px solid #555; /* Darker border */
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s ease;
  width: 100%; /* Increased button width */
  margin: 5px 0; /* Adjusted margin to match input fields */
}